import { handleErrorWithSentry, init, Replay } from "@sentry/sveltekit";
import { PUBLIC_ENVIRONMENT as environment } from '$env/static/public';

init({
  debug: false,
  dsn: 'https://7e00fe5f6d7d1677cccc85cea965fc75@o4505464480333824.ingest.sentry.io/4506200841453568',
  enabled: environment !== 'local',
  environment: environment,
  tracesSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  integrations: [new Replay()]
});

export const handleError = handleErrorWithSentry();
